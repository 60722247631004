import React, { FC, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classes from "./Logo.module.scss";
import useWindowSize from "../../hooks/useWindowSize";
import Image from "next/image";

const Logo: FC = () => {
	const { asPath, pathname } = useRouter();
	const [logo, setLogo] = useState("/img/logo.png");
	const { width } = useWindowSize();

	const [scrollPosition, setScrollPosition] = useState(0);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		if (asPath === "/") {
			setLogo("/img/logo.png");
		} else {
			setLogo("/img/aboutus/about-us-logo-blue.png");
		}
	}, [asPath]);

	useEffect(() => {
		if (scrollPosition > 10 && asPath === "/" && width > 575) {
			setLogo("/img/aboutus/about-us-logo-blue.png");
		} else if (asPath !== "/" && width > 575) {
			setLogo("/img/aboutus/about-us-logo-blue.png");
		} else if (width < 575) {
			setLogo("/img/aboutus/about-us-logo-blue.png");
		} else {
			setLogo("/img/logo.png");
		}
	}, [scrollPosition, asPath, width]);

	return (
		<Link href="/" passHref>
			<div className={`${classes.logoHolder} logo-holder`}>
				<Image src={logo} layout="fill" alt={"logo"} />
			</div>
		</Link>
	);
};

export default Logo;
