import Link from "next/link";
import { useRouter } from "next/router";
import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import { StaticContext } from "../../context/static-context/static-context";
import { useScrollLock } from "../../hooks/useScroll";

import styles from "./Header.module.scss";

import dynamic from "next/dynamic";
import { MemoizedSearchBarMob } from "../search-bar/search-bar-mob";

import Skeleton from "@mui/material/Skeleton";
import Logo from "../../UI/Logo/Logo";
import useWindowSize from "../../hooks/useWindowSize";
// import { MemoizedFilterMobile } from "../filter-mobile/filter-mobile";
const Button = dynamic(() => import("../common/button/button"));
const NavigationMenu = dynamic(() => import("./naviagtion-menu"));

interface HeaderProps {
	showBurgerMenu: boolean;
	setShowBurgetMenu: Dispatch<SetStateAction<boolean>>;
}

function Header({
	showBurgerMenu,
	setShowBurgetMenu,
}: HeaderProps): JSX.Element {
	const { lockScroll, unlockScroll } = useScrollLock();
	const contextData = useContext(StaticContext);
	const [showSearch, setShowSearch] = useState<boolean>(true);
	const [scrollPosition, setScrollPosition] = useState(0);
	const { isHidden, setIsHidden } = contextData;
	const router = useRouter();
	const { width } = useWindowSize();

	const [loaderSearchBar, setLoaderSearchBar] = useState<boolean>(false);
	const [boxActiveCss, setBoxActiveCss] = useState<string>(
		styles.headerBoxActive,
	);
	const [headerBackGroundColor, setHeaderBackGroundColor] = useState<string>(
		styles.headerBackGroundColor,
	);

	useEffect(() => {
		if (router.asPath === "/") {
			setBoxActiveCss(styles.headerBoxActive);
			setHeaderBackGroundColor("");
		} else {
			setBoxActiveCss("");
			setHeaderBackGroundColor(styles.headerBackGroundColor);
		}
	}, [router.asPath]);

	useEffect(() => {
		showBurgerMenu ? lockScroll() : unlockScroll();
	}, [showBurgerMenu, lockScroll, unlockScroll]);

	useEffect(() => {
		window.location.href.includes("/inventory/")
			? setShowSearch(false)
			: setShowSearch(true);
	}, [contextData]);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoaderSearchBar(true);
		}, 4000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<>
			<div
				className={`${styles.header} ${headerBackGroundColor} ${
					router.asPath === "/" ? styles.homePageHeader : ""
				}`}
			>
				<div
					className={`${styles.headerBox} ${styles.headerBoxColor} ${
						scrollPosition !== 0 ? boxActiveCss : ""
					}`}
				></div>
				<div onClick={() => setIsHidden(true)}>
					<Logo />
				</div>

				<div className={styles.mobFilterBox}>
					{width < 570 && loaderSearchBar && (
						<MemoizedSearchBarMob setShowBurgetMenu={setShowBurgetMenu} />
					)}

					{width < 570 && !loaderSearchBar && (
						<Skeleton height={"46px"} width={"240px"} />
					)}
				</div>

				<div style={{ display: "flex", alignItems: "center" }}>
					<div className={styles.navMenuWrapper}>
						<NavigationMenu isScrool={scrollPosition > 10} />
					</div>

					<div className={styles.navMenuWrapperRight}>
						<Link href="/inventory">
							<a style={{ marginLeft: "0rem", zIndex: "2" }}>
								<Button className={styles.viewInventory} title="Shop Now!" />
							</a>
						</Link>

						<div
							className={styles.burgerButton}
							onClick={() => {
								setIsHidden(true);
							}}
						>
							<button
								className={`${styles.menuToggle} ${
									showBurgerMenu ? styles.isActive : ""
								}`}
								onClick={() => setShowBurgetMenu(!showBurgerMenu)}
							>
								Menu
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Header;
