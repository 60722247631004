import React, {
	useEffect,
	useState,
	useContext,
	useCallback,
	Dispatch,
	SetStateAction,
} from "react";
import { StaticContext } from "../../context/static-context/static-context";
import styles from "./search-bar-mob.module.scss";
import lodash from "lodash";
import {
	getParametersFromRouter,
	ISearchState,
} from "../../../pages/inventory/InventoryPage";
import { useRouter } from "next/router";

interface SearchBarMobProps {
	setShowBurgetMenu: Dispatch<SetStateAction<boolean>>;
}

function SearchBarMob({ setShowBurgetMenu }: SearchBarMobProps): JSX.Element {
	const contextData = useContext(StaticContext);
	const router = useRouter();
	const query = router.query;
	const searchParameters = getParametersFromRouter(router);
	const [searchState, setSearchState] = useState<ISearchState>(
		searchParameters,
	);

	const { isHidden, setIsHidden } = contextData;
	const [isFocus, setIsFocus] = useState(false);
	const [isBlur, setIsBlur] = useState(false);
	const [text, setText] = useState<string>(searchParameters.fullTextSearch);
	const [textSmall, setTextSmall] = useState<string>("");

	const [searchTimeout, setSearchTimeout] = useState<
		NodeJS.Timeout | undefined
	>(undefined);

	const onFormSubmit = (e: any) => {
		e.preventDefault();

		const searchIsEqual = lodash.isEqual(searchParameters, searchState);

		const newQuery: any = {
			...query,
			...searchState,
			skip: searchIsEqual ? searchParameters.skip : 0,
			take: searchParameters.take,
		};

		for (let key in newQuery) {
			if (newQuery[key] === undefined || newQuery[key] === null) {
				delete newQuery[key];
			} else if (newQuery[key].length === 0) {
				delete newQuery[key];
			} else if (newQuery[key] === "") {
				delete newQuery[key];
			}
		}

		router.push(
			{
				query: newQuery,
			},
			undefined,
			{ scroll: false },
		);
	};

	const updateSearchState = (p: Partial<ISearchState>) => {
		setSearchState({
			...searchState,
			...p,
		});
	};

	const handleKeyPress = (event: React.KeyboardEvent): any => {
		if (event.key === "Enter") {
			onFormSubmit(event);
		}
	};

	useEffect(() => {
		setText(searchParameters.fullTextSearch);
	}, [contextData]);

	const toggleFilterPanel = useCallback(() => {
		setIsHidden((value) => !value);
	}, [setIsHidden]);

	const focusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		setIsFocus(true);
		setIsBlur(false);
	};

	const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		setIsFocus(false);
		setIsBlur(true);
	};

	const textSmallFunction = (text: string): string => {
		let result: string[] = text.split("");

		if (result.length < 12) {
			return text;
		} else {
			return `${result.splice(0, 12).join("")}...`;
		}
	};

	const scheduleSearchTimeout = () => {
		removeSearchTimeout();
		const timeoutID = setTimeout(() => {
			if (JSON.stringify(searchState) !== JSON.stringify(searchParameters))
				onFormSubmit({
					preventDefault: () => {},
				});
		}, 750);

		setSearchTimeout(timeoutID);
	};

	const removeSearchTimeout = () => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
			setSearchTimeout(undefined);
		}
	};

	useEffect(() => {
		setTextSmall(textSmallFunction(text));
		// if (text.length >= 3) {
		// 	onFormSubmit({
		// 		preventDefault: () => {},
		// 	});
		// }

		if (text.length >= 1 && router.pathname !== "/inventory") {
			router.push(`/inventory?fullTextSearch=${text}`);
		}

		if (router.pathname === "/inventory") {
			scheduleSearchTimeout();
		}
	}, [text]);

	return (
		<>
			<div className={styles.searchBarMobBox}>
				<div className={styles.iconSearch}>
					<svg
						width="19"
						height="18"
						viewBox="0 0 19 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.9231 18H16.7131C16.4458 17.9306 16.2062 17.7805 16.0269 17.5702C14.7414 16.2765 13.4541 14.9858 12.1651 13.6982C11.9659 13.4987 11.9677 13.5023 11.7269 13.6474C10.1327 14.642 8.21563 14.9788 6.3787 14.5868C5.01394 14.3163 3.75823 13.6511 2.76675 12.6735C1.6676 11.6282 0.923541 10.2637 0.639412 8.77244C0.581476 8.46957 0.547073 8.16126 0.5 7.85657V6.86998C0.527158 6.66686 0.548875 6.46374 0.581464 6.26243C0.720439 5.30837 1.04786 4.39164 1.54462 3.56582C2.04138 2.73999 2.69752 2.02165 3.47464 1.45278C4.51447 0.680598 5.7389 0.196196 7.02484 0.0482479C8.31078 -0.0997 9.61295 0.0940155 10.8005 0.609937C11.988 1.12586 13.019 1.94581 13.7899 2.98737C14.5608 4.02893 15.0445 5.2554 15.1922 6.54353C15.3843 8.21461 14.9941 9.90083 14.0878 11.3169C14.0589 11.3641 14.0335 11.4148 13.9991 11.4747C14.0552 11.5255 14.1041 11.5672 14.1494 11.6125L18.1795 15.6206C18.6068 16.0468 18.6068 16.5582 18.1795 16.9862C17.9985 17.1676 17.8011 17.3489 17.6219 17.5484C17.4406 17.7671 17.1967 17.9247 16.9231 18ZM3.38956 7.36147C3.38956 8.24944 3.65237 9.11748 4.14479 9.85585C4.6372 10.5942 5.33709 11.1698 6.15601 11.5098C6.97492 11.8497 7.87609 11.9389 8.74557 11.7659C9.61505 11.5929 10.4138 11.1656 11.0409 10.538C11.668 9.91034 12.0952 9.11055 12.2686 8.23973C12.442 7.3689 12.3537 6.46613 12.0149 5.64555C11.6762 4.82496 11.1022 4.12339 10.3655 3.62954C9.6288 3.13569 8.76245 2.87172 7.87599 2.87101C6.68747 2.8734 5.54826 3.34715 4.70752 4.18865C3.86677 5.03015 3.3929 6.17093 3.38956 7.36147Z"
							fill="#DBDBDB"
						/>
					</svg>
				</div>

				<input
					type="text"
					value={isFocus ? text : textSmall}
					placeholder="Search Loads"
					className={styles.searchBarMobBoxInput}
					onFocus={focusHandler}
					onBlur={blurHandler}
					onKeyPress={handleKeyPress}
					onChange={(e) => {
						updateSearchState({
							fullTextSearch: e.target.value,
						});
						setText(e.target.value);
					}}
				/>

				<div onClick={toggleFilterPanel}>
					<div
						className={`${styles.searchBarMobBoxFilter} ${
							isFocus ? styles.searchBarMobBoxFilterActive : ""
						}`}
						onClick={() => setShowBurgetMenu(false)}
					>
						<div
							className={`${styles.searchBarMobBoxFilter__iconBox} ${
								isHidden ? styles.searchBarMobBoxFilter__iconBoxActive : ""
							}`}
						>
							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									height="13"
									viewBox="0 0 24 24"
									width="13"
								>
									<path d="M0 0h24v24H0z" fill="none" />
									<path
										d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10
										16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14
										4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"
										fill="#fff"
									/>
								</svg>
							</div>

							<div className={styles.searchBarMobBoxFilter__iconCLose}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									height="15"
									viewBox="0 0 24 24"
									width="15"
								>
									<path d="M0 0h24v24H0z" fill="none" />
									<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="#fff" />
								</svg>
							</div>
						</div>

						<div
							className={`${styles.searchBarMobBoxFilter__text} ${
								isFocus ? styles.searchBarMobBoxFilter__textActive : ""
							}`}
						>
							FILTER
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export const MemoizedSearchBarMob = React.memo(SearchBarMob);
